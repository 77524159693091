import React from 'react'
import { Link } from 'gatsby'
import { scroller } from 'react-scroll'
import { Button, Head } from '@components'
import * as st from '@assets/styl/NotFound.module.styl'

export default () => <>
  <Head path="/404/" />

  <section className={st.core}>
    <div className={st.grid}>
      <div className="icon-logo"></div>
      <div className={st.content}>
        <div>
          <h1>Erro 404</h1>
          <h2>Página não encontrada</h2>
          <p>A página que você está tentando acessar não existe ou está fora do ar temporariamente. Verifique o endereço e tente novamente.</p>
        </div>
        <div>
          <p>O que você deseja fazer?</p>
          <div>
            <Button to="/" className={st.btn} onClick={() => setTimeout(() => scroller.scrollTo('solucoes'), 300)}>Conhecer nossas soluções</Button>
            <Button to="/" className={st.btn} onClick={() => setTimeout(() => scroller.scrollTo('contato'), 300)}>Entrar em contato</Button>
          </div>
          <Link to="/" className={st.link}>Ir para a home do site Wecharge.</Link>
        </div>
      </div>
    </div>
  </section>
</>